import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Header from "../components/header"
import Section from "../components/section"
import TwmButton from "../components/twm-button"

const AboutYouPage = props => (
  <Layout>
    <SEO title="True Wealth Management" />

    <BackgroundImage
      fluid={props.data.leedsOwlImage.childImageSharp.fluid}
      alt="Leeds Civic hall owl"
    >
      <Header headerText="True Wealth Management"></Header>
    </BackgroundImage>
    <Section>
      <p>
        True Wealth Management ('TWM') serves to meet the financial planning and
        wealth management needs of its clients by focusing on what ‘true wealth’
        means for them.
      </p>
      <p>
        It was launched in 2011 and builds on the principles and processes
        developed by its management team in their consultancy businesses True
        Financial Planning (established in 2003) and Veracity Asset
        Transformation Service (launched in 2009).
      </p>
      <p>
        {" "}
        All of its advisers are experts in the field of financial planning and
        highly qualified (to at least ‘level 6’ – the level required to become
        ‘Chartered’ and/or ‘Certified’ financial planners).
      </p>

      <TwmButton toRef="/contact-us">Contact Us</TwmButton>
    </Section>
  </Layout>
)

export default AboutYouPage

export const pageQuery = graphql`
  query {
    leedsOwlImage: file(relativePath: { eq: "leeds-owl.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
